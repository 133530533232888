import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import moment from "moment"; // Import moment for date formatting
import { Button } from "@mui/material";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import { useState } from "react";
import { useAuth } from "../../Utility/AuthProvider";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function CongratsModal({
  open,
  onClose,
  agreementData,
  tenantData,
  unitData,
  addAgreement,
}) {
  const [viewDetailsLoading, setViewDetailsLoading] = useState(false);
  const [viewDetailsError, setViewDetailsError] = useState(null);
  const { jwt } = useAuth(); // Access JWT if needed
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false); // Define isUpdate
  const [originalAgreement, setOriginalAgreement] = useState(null); // Define originalAgreement

  console.log("Agreement Data entered 💋 -", agreementData);
  console.log("Tenant Data entered 🇮🇳 -", tenantData);
  console.log("Unit Data for Agreement 💎 -", unitData);

  console.log("🦿😈🧢🔎 add agreement res ---", addAgreement);

  console.log(
    "Tenant Data on CongratsModal via AgreementOverview props 😀 -",
    tenantData
  );

  const rent =
    addAgreement?.response?.rent && addAgreement.response.rent.length > 0
      ? addAgreement.response.rent[0].rent
      : "N/A";

  const created =
    addAgreement?.response?.created && addAgreement.response.created;

  const formattedCreatedDate = created
    ? moment(created).format("MMM DD, YYYY at hh:mm A")
    : "N/A";

  const handleViewDetails = useCallback(() => {
    console.log("🎯 Agreement Response:", addAgreement.response);

    navigate(`/MyProperties/${addAgreement.response.unit_name}`, {
      state: {
        entity_id: addAgreement.response.unit_id, // Use unit_id instead of entity_id
        needsUpdate: true,
        updateData: {
          agreement_id: addAgreement.response.entity_id,
          rent: addAgreement.response.rent?.[0]?.rent,
          unit_id: addAgreement.response.unit_id,
          occupancy_status: "rented",
          tenant_name: tenantData[0]
            ? `${tenantData[0].first_name} ${tenantData[0].last_name}`
            : "N/A",
          isUpdate,
          building_id: addAgreement.response.building_id,
          tenantCount: tenantData.length,
          originalAgreementTenantCount:
            originalAgreement?.tenant_id.length || 0,
        },
      },
    });
  }, [addAgreement, tenantData, isUpdate, originalAgreement, navigate]);

  const getPrimaryTenant = (tenantIds) => {
    // Assuming tenantIds is an array of tenant IDs. You might need to fetch tenant details separately.
    if (!tenantIds || tenantIds.length === 0) return "N/A";

    // Placeholder: Replace with actual logic to fetch tenant name based on ID
    return "Tenant " + tenantIds[0];
  };

  const getRentAmount = (rentArray) => {
    if (!rentArray || rentArray.length === 0) return "N/A";
    return rentArray[0].rent; // Assuming rent is in the first element.
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Congratulations, Agreement Added Successfully!
        </Typography>
        <Typography>Agreement Details</Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Rent Amount: {rent}
          {/* Optional chaining */}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Primary Tenant:{" "}
          {tenantData && tenantData.length > 0
            ? `${tenantData[0].first_name} ${tenantData[0].last_name}`
            : "N/A"}{" "}
          {/* Conditional rendering */}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Date Created: {formattedCreatedDate || "N/A"}
        </Typography>{" "}
        {/* Conditional rendering */}
        {/* ... other JSX */}
        <Button onClick={handleViewDetails} variant="contained">
          View Details
        </Button>
      </Box>
    </Modal>
  );
}
