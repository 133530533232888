import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Utility/AuthProvider";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import { FaHouse } from "react-icons/fa6";
import { HiUsers } from "react-icons/hi";
import { IoIosAddCircle } from "react-icons/io";
import CustomImage from "../../Utility/CustomImage";

function Buildings() {
  const { jwt } = useAuth();
  const [buildings, setBuildings] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBuildings = async () => {
      try {
        const buildingResponse = await APICall(
          "GET",
          paths.building.fetch,
          undefined,
          undefined,
          jwt
        );
        setBuildings(buildingResponse.response);
      } catch (error) {
        console.error("Error fetching buildings:", error);
      }
    };

    fetchBuildings();
  }, [jwt]);

  console.log("This is get Building response -", buildings);

  const handleBuildingClick = (building) => {
    navigate(`/Units?buildingId=${building.entity_id}`, { state: building });
  };

  const getInitials = (name) => {
    const nameParts = name.split(" ");
    const initials = nameParts
      .map((part) => part.charAt(0))
      .join("")
      .toUpperCase();
    return initials;
  };

  const handleAddBuildingClick = () => {
    navigate("/AddNewBuilding");
  };

  const filteredBuildings = buildings.filter((building) =>
    `${building.building_name} ${building.address}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  console.log("Filtered Buildings page data -", filteredBuildings);

  return (
    <div className="border-2 p-10 rounded-2xl">
      <div className="flex items-center justify-between">
        <p className="font-black">My Buildings</p>
        <button
          onClick={() => navigate("/AddNewBuilding")}
          className="flex items-center bg-blue-700 text-white text-xs p-3 rounded-full"
        >
          <IoIosAddCircle size={15} />
          Add New
        </button>
      </div>

      <div className="flex items-center border-gray border mt-4 p-2 rounded-full gap-2">
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="flex-grow outline-none bg-transparent"
        />
      </div>

      <div className="grid grid-cols-2 gap-4 mt-4">
        {filteredBuildings.map((building) => {
          const imageLocation =
            building.media && building.media.location
              ? building.media.location
              : null;

          return (
            <div
              key={building.entity_id}
              className="flex justify-between mb-4 border-2 p-4 rounded-2xl"
              onClick={() => handleBuildingClick(building)}
            >
              <div className="flex justify-center border border-blue-700 rounded-xl">
                <div className="rounded-xl overflow-hidden flex-shrink-0">
                  <CustomImage
                    location={imageLocation}
                    jwt={jwt}
                    params={{ quality: "high" }}
                    className="h-40 w-40"
                  />
                </div>
              </div>
              <div className="building-data-container text-xs ml-4 p-2 rounded-lg flex flex-col justify-between flex-grow">
                <div>
                  <p className="text-black font-bold text-lg">
                    {building.building_name || "--unnamed building--"}
                  </p>
                  <p className="text-black mb-2">
                    {building.address || "--no address--"}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  {/* Number of Units */}
                  <div className="flex items-center gap-2">
                    <FaHouse size={15} />
                    <p>{building.no_of_units || 0}</p>{" "}
                    {/* Show 0 if no_of_units is undefined or 0 */}
                    <p>
                      {(building.no_of_units || 0) === 1 ? "Unit" : "Units"}
                    </p>
                  </div>

                  {/* Number of Tenants */}
                  <div className="flex items-center gap-2">
                    <HiUsers />
                    <p>{building.no_of_tenants || 0}</p>{" "}
                    {/* Show 0 if no_of_tenants is undefined or 0 */}
                    <p>Tenants</p>
                  </div>
                </div>

                <div className="flex items-center justify-start float-end">
                  <button
                    onClick={() => handleBuildingClick(building)}
                    className="bg-blue-700 text-white text-xs px-4 py-2 rounded-full"
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Buildings;
