import {
  api_url,
  currentPlatform,
  frontendVerificationKey,
  paths,
  developmentHeaderKey,
} from "./Constants";

const APICall =
  /**
   * Make a fetch API call to the server. This function is used to convert the body of the request to form data if it is an object. It also appends the JWT token to the request headers if it is provided and appends any additional query parameters to the URL. It also logs the requested URL of the API call to the console.
   * @date 2/2/2024 - 12:38:41 PM
   *
   * @async
   * @param {String} method - The HTTP method of the request (GET, POST, PUT, DELETE). POST will be used whenever there is a need to pass sensitive data in the body of the request. PUT as well but for updating data. DELETE will be used to delete data from the server. GET will be used to retrieve data from the server. GET requests are not allowed to have a body. So the body will be undefined for GET requests. Similarly, DELETE requests will not have a body (by choice). So the body will be undefined for DELETE requests as well. Params will be undefined for POST and PUT requests. Params will be used to append additional query parameters to the URL for GET requests. The JWT token will be appended to the request headers for all requests if it is provided.
   * @param {String} path - The path to the url file. This will be appended to the base URL of the server. For example - paths.user.login (user/login)
   * @param {Object | undefined} body - The body of the data to be passed to the server. This will be converted to form data if it is an object. If it is already a form data, it will be used as is. If it is undefined, it will not be included in the request. This is the case for GET requests and DELETE requests
   * @param {Object | undefined} params - Additional parameters to append to the URL as query parameters. This is used for GET | DELETE requests. It will be undefined for POST and PUT requests
   * @returns {Object | Media (PDF or Image) | Boolean} - The response from the server (converted to JSON) or the media file itself if the API call is a media file. If an error occurred, it will return false
   */
  async (method, path, body, params, jwt = undefined) => {
    async function innerAPICall() {
      try {
        const requestBody = body instanceof FormData ? body : new FormData();

        // console.log("Body: ", body);

        // If body is an object, convert its properties to form fields
        if (typeof body === "object" && !(body instanceof FormData)) {
          for (const key in body) {
            if (Object.hasOwnProperty.call(body, key)) {
              requestBody.append(key, body[key]);
            }
          }
        }

        const headers = {
          Accept: "application/json",
          ...getAuthorizationHeaders(jwt),
        };

        if (process.env.NODE_ENV === "development") {
          // console.log("we are here inside OTP method")
          headers[developmentHeaderKey] = true;
        }
        // console.log("Process Environment -",process.env)

        if (params) {
          const paramsString = new URLSearchParams(params).toString();
          path += "?" + paramsString;
        }

        // console.log('API Call: ' + method + " " + api_url + path)
        const response = await fetch(api_url + path, {
          method: method,
          headers: headers,
          body: method != "GET" && method != "DELETE" ? requestBody : undefined, // Only include the body for non-GET/DELETE requests
        });

        const routesThatHaveMediaInResponse = [
          paths.media.download,
          paths.transaction.download,
        ];
        if (routesThatHaveMediaInResponse.includes(path)) {
          // console.log("API Call is a media file. Returning direct response without converting to json")
          return response;
        }
        return await response.json();
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    return await innerAPICall();

    const maxTries = 3;
    let tries = 0;

    while (tries < maxTries) {
      const response = await innerAPICall();
      if (
        response !== false &&
        typeof response === "object" &&
        response.status !== "error"
      ) {
        return response;
      }
      console.log("API Call failed. Retrying...", tries + 1, "of", maxTries);
      tries++;
    }
  };

export default APICall;

export const getAuthorizationHeaders =
  /**
   * Get the headers for an API request. This function appends the JWT token to the request headers if it is provided. It also appends the platform and verification key to the request headers.
   * @date 25/04/2024 - 04:53:41 PM
   *
   * @param {String | undefined} jwt - The JWT token for the user
   * @returns {Object} - The headers for the API request
   */
  (jwt) => {
    // Add platform and verification key to the request headers
    const headers = {
      Platform: currentPlatform,
      PlatformKey: frontendVerificationKey,
    };

    if (jwt) {
      headers.Authorization = jwt;
    }

    return headers;
  };

export const GetMediaURL =
  /**
   * Get the actual media URL for a media "location" in cloud storage. This was used to quickly switch between production server and local server for testing. This function appends the location to the base URL and in addition, appends the quality of the requested URL as a query parameter. It also appends any additional parameters to the URL as query parameters.
   * @date 2/2/2024 - 12:38:41 PM
   *
   * @param {String} path - The path to the media file. Usually paths.media.download (media/download)
   * @param {String} location - The location of the media file in cloud storage
   * @param {Object} params - Additional parameters to append to the URL as query parameters. Usually the quality of the media file
   * @returns {Image | Boolean} - The URL of the media file or false if an error occurred
   */
  (path, location, params) => {
    try {
      let final_url = api_url + path + "?location=" + location;

      if (params) {
        const paramsString = new URLSearchParams(params).toString();
        if (paramsString.length > 0) {
          final_url += "&" + paramsString;
        }
      }

      // console.log("Final Media URL: " + final_url);
      return final_url;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

export const DeleteMedia =
  /**
   * Send a DELETE request to the server to delete a media file in cloud storage
   * @date 2/2/2024 - 12:36:41 PM
   *
   * @async
   * @param {String} mediaLocation - The location of the media file to delete
   * @param {String} jwt - The JWT token for the user
   * @returns {Object | Boolean} - The response from the server (converted to JSON) or false if an error occurred
   */
  async (mediaLocation, jwt) => {
    try {
      const body = {
        location: mediaLocation,
      };
      const response = await APICall(
        "DELETE",
        paths.media.delete,
        undefined,
        body,
        jwt
      );
      return response;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

export const GetUploadMediaBaseLocation =
  /**
   * Append location of the media to the user's user id (entity_id)
   * @date 11/03/2024 - 18:26:47
   *
   * @param {String} location - The location where the media will be stored in cloud storage
   * @param {Object} user - The user object (should contain the property entity_id which is the same as the id in jwt token)
   * @returns {String} - The location of the media in cloud storage. It is the user's entity_id appended with the location
   */
  (location, user) => {
    if (!location || !user)
      throw new Error(
        "Error in GetUploadMediaBaseLocation: Location and User are required"
      );
    if (!user?.entity_id)
      throw new Error(
        "Error in GetUploadMediaBaseLocation: User entity_id is required"
      );
    return String(String(user.entity_id) + "/" + location);
  };

export function formatDateForAPI(input) {
  console.log("Received input:", input);

  // If input already has the formatted date, return it
  if (input && input.pythonDateTimeFormat !== undefined) {
    console.log("Input has pythonDateTimeFormat property");
    return input.pythonDateTimeFormat;
  }

  let date;

  // If input is an object with year, month, and day
  if (
    typeof input === "object" &&
    "year" in input &&
    "month" in input &&
    "day" in input
  ) {
    date = new Date(input.year, input.month - 1, input.day, 23, 20, 20); // Set default time: 23:20:20
  }
  // If input is a string in YYYY-MM-DD format
  else if (typeof input === "string" && /^\d{4}-\d{2}-\d{2}$/.test(input)) {
    date = new Date(input + "T23:20:20"); // Ensures proper parsing with time
  } else {
    console.error("Invalid input format:", input);
    return input; // Return as-is if input is invalid
  }

  // Format date as: "Mon, 20 Jan 2025 23:20:20"
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  }).format(date);

  console.log("Formatted Date:", formattedDate);
  return formattedDate;
}
