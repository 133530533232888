import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../Utility/AuthProvider";
import { useUnitDetails } from "./UnitDetailsContext";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import CircularInitial from "../UpcomingRent/CircularInitial";
import PhoneIcon from "../../assets/DashboardIcons/Phone.svg";
import CustomImage from "../../Utility/CustomImage";
import VacantBtn from "../../components/Buttons/VacantBtn";
import RentedBtn from "../../components/Buttons/RentedBtn";
import { PiBuildingsLight } from "react-icons/pi";
import WhatsAppComponent from "../Reports/components/WhatsappComponent";
import { extractDatePart, calculateDueDate } from "../../Utility/Utils";
import Facility from "./Facility";
import CustomModalEditUnit from "../Modals/CustomModalEditUnit";
import CustomButton from "../Buttons/CustomButton";
import MonthIcons from "../../Utility/MonthIcons";
import TenantDetailsModal from "../Modals/TenantDetailsModal";

import { IoIosBed, IoIosWifi, IoIosSnow } from "react-icons/io";
import { BiCctv } from "react-icons/bi";
import { PiTelevisionDuotone } from "react-icons/pi";
import { CiParking1 } from "react-icons/ci";
import { LuRefrigerator } from "react-icons/lu";
import { MdLocalLaundryService } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import { CiPower } from "react-icons/ci";
import { PiFan } from "react-icons/pi";
import { FaHandsHelping } from "react-icons/fa";

function UnitDetails() {
  // const location = useLocation();
  const { route } = useParams();
  const { jwt } = useAuth();
  const navigate = useNavigate();
  const {
    unitDetails,
    loading: unitLoading,
    error: unitError,
    fetchUnitDetails,
    updateUnitDetails,
  } = useUnitDetails(); // Use the context

  const [tenantData, setTenantData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [loadingBuildings, setLoadingBuildings] = useState(true);
  const [errorBuildings, setErrorBuildings] = useState(null);
  const [tenantDetailsModal, setTenantDetailsModal] = useState(false);
  const [transactionData, setTransactionData] = useState(null);
  const [paymentsData, setPaymentsData] = useState(null);

  const [updateError, setUpdateError] = useState(null);
  const location = useLocation();
  // const updateData = location.state?.updateData;
  // const needsUpdate = location.state?.needsUpdate;

  const unit = location.state || {};
  // const entity_id = location.state?.entity_id;

  // Destructure all needed values from location.state
  const { entity_id, needsUpdate, updateData } = location.state || {};

  console.log("🔍 Entity ID on Unit Details --", entity_id);
  console.log("📦 Full location state --", location.state);

  // Modified useEffect to use the destructured entity_id
  useEffect(() => {
    if (entity_id) {
      // Check directly for entity_id
      console.log("🔄 Fetching unit details for entity_id:", entity_id);
      fetchUnitDetails(entity_id, jwt);
    } else {
      console.log("⚠️ No entity_id available for fetching unit details");
    }
  }, [entity_id, fetchUnitDetails, jwt]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const paymentDataResponse = await APICall(
          "POST",
          paths.payments.fetch,
          undefined,
          undefined,
          jwt
        );
        setPaymentsData(paymentDataResponse.response.payments);

        const buildingsResponse = await APICall(
          "GET",
          paths.building.fetch,
          undefined,
          undefined,
          jwt
        );
        setBuildings(buildingsResponse.response);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingBuildings(false);
      }
    };

    fetchData();
  }, [unit.entity_id, jwt]);

  const handleUpdateSuccess = async (updatedDetails) => {
    await updateUnitDetails(updatedDetails, jwt);
    setIsModalOpen(false);
  };

  // useEffect(() => {
  //   const fetchTenantData = async () => {
  //     try {
  //       const response = await APICall(
  //         "GET",
  //         paths.tenant.fetchAll,
  //         undefined,
  //         undefined,
  //         jwt
  //       );
  //       setTenantData(response.response);
  //     } catch (error) {
  //       console.error("Error fetching tenant data:", error);
  //     }
  //   };

  //   fetchTenantData();
  // }, [jwt]);

  // Add this new useEffect for handling updates
  useEffect(() => {
    const handleUnitUpdate = async () => {
      console.log("🚀 Starting unit update process...");
      console.log("📋 Update Data:", {
        needsUpdate,
        updateData,
        unitId: unit?.entity_id,
      });

      try {
        // Check conditions
        if (!needsUpdate || !updateData || !unit?.entity_id) {
          console.log("⛔ Update conditions not met, skipping update");
          return;
        }

        // Update unit
        console.log("🏠 Updating unit with data:", {
          agreement_id: updateData.agreement_id,
          rent: updateData.rent,
          unit_id: updateData.unit_id,
          occupancy_status: updateData.occupancy_status,
          tenant_name: updateData.tenant_name,
        });

        const unitUpdateResponse = await APICall(
          "PUT",
          paths.unit.update,
          {
            agreement_id: updateData.agreement_id,
            rent: updateData.rent,
            unit_id: updateData.unit_id,
            occupancy_status: updateData.occupancy_status,
            tenant_name: updateData.tenant_name,
          },
          undefined,
          jwt
        );

        console.log("✅ Unit update successful:", unitUpdateResponse);

        // Calculate tenant count updates
        let shouldUpdateCountTenantCount = false;
        let countToUpdate = 0;

        if (updateData.isUpdate) {
          console.log("🔄 Calculating tenant count for update...");
          const newTenantCount = updateData.tenantCount;
          const oldTenantCount = updateData.originalAgreementTenantCount;

          console.log("👥 Tenant counts:", {
            new: newTenantCount,
            old: oldTenantCount,
          });

          if (newTenantCount !== oldTenantCount) {
            shouldUpdateCountTenantCount = true;
            countToUpdate = newTenantCount - oldTenantCount;
            console.log("📊 Tenant count needs update. Delta:", countToUpdate);
          }
        }

        // Update building count if needed
        if (!updateData.isUpdate || shouldUpdateCountTenantCount) {
          console.log("🏢 Updating building count...");
          const buildingUpdateResponse = await APICall(
            "PUT",
            paths.building.updateCount,
            {
              building_id: updateData.building_id,
              tenant_count: updateData.isUpdate
                ? countToUpdate
                : updateData.tenantCount,
            },
            undefined,
            jwt
          );
          console.log(
            "✅ Building count update successful:",
            buildingUpdateResponse
          );
        }

        // Refresh with the correct unit_id
        console.log("🔄 Refreshing unit details with unit_id:", entity_id);
        await fetchUnitDetails(entity_id, jwt);
        console.log("✨ Unit details refresh complete");
      } catch (error) {
        console.error("❌ Error in unit update process:", error);
        setUpdateError(
          error.message || "An error occurred while updating the unit."
        );
      }

      console.log("🏁 Unit update process complete");
    };

    handleUnitUpdate();
  }, [needsUpdate, updateData, jwt, unit?.entity_id, fetchUnitDetails]);

  if (updateError) {
    return <p>Error updating unit: {updateError}</p>;
  }

  if (unitLoading || loadingBuildings) return <p>Loading...</p>;
  if (unitError || errorBuildings)
    return <p>Error: {unitError?.message || errorBuildings?.message}</p>;

  if (!unitDetails) return <p>No unit data available 😔</p>;

  const { agreement, tenant, transactions, unitdata } = unitDetails;
  const agreementDetails = agreement || {};
  const amenitiesList = unitDetails.unit.amenities
    ? unitDetails.unit.amenities.split(",")
    : [];

  console.log(
    "Agreement data on Unit Details page via props -",
    agreementDetails
  );

  const getInitials = (name) => {
    const nameParts = name.split(" ");
    return nameParts
      .map((part) => part.charAt(0))
      .join("")
      .toUpperCase();
  };

  const mapAmenitiesToIcons = (amenity) => {
    switch (amenity.trim().toLowerCase()) {
      case "wifi":
        return { icon: IoIosWifi, name: "WiFi" };
      case "cctv":
        return { icon: BiCctv, name: "CCTV" };
      case "television":
        return { icon: PiTelevisionDuotone, name: "Television" };
      case "parking":
        return { icon: CiParking1, name: "Parking" };
      case "refrigerator":
        return { icon: LuRefrigerator, name: "Refrigerator" };
      case "laundry":
        return { icon: MdLocalLaundryService, name: "Laundry" };
      case "security":
        return { icon: MdOutlineSecurity, name: "Security" };
      case "inverter":
        return { icon: CiPower, name: "Inverter" };
      case "bed":
        return { icon: IoIosBed, name: "Bed" };
      case "fan":
        return { icon: PiFan, name: "fan" };
      case "AC":
        return { icon: IoIosSnow, name: "AC" };
      default:
        return { icon: FaHandsHelping, name: "Friendly" };
    }
  };

  // const handleUpdate = (updatedUnitDetails) => {
  //   // Find the index of the unit that was updated
  //   const updatedUnits = units.map((unit) =>
  //     unit.entity_id === updatedUnitDetails.unit.entity_id
  //       ? updatedUnitDetails.unit
  //       : unit
  //   );

  //   // Update the state with the new list of units
  //   setUnits(updatedUnits);
  // };
  // console.log("Unit Details -", unitDetails);
  return (
    <div className="text-xs border-2 rounded-2xl p-10">
      {/* row1 */}
      <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Container for Unit Details and Agreement Details */}
        <div className="flex flex-col justify-between rounded-lg p-4">
          {/* Unit Details Container */}
          <div className="flex flex-col mb-4">
            <div className="flex items-center justify-between mb-4">
              <p className="text-xl font-semibold">Unit</p>
              {agreement ? <WhatsAppComponent selectedTenant={tenant} /> : null}
            </div>
            <div className="bg-white rounded-lg p-5 flex flex-col md:flex-row gap-4">
              <div className="flex-1">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center">
                    <p className="text-lg pr-4">{unitDetails.unit.unit_name}</p>
                    {unitDetails.unit.occupancy_status === "vacant" ? (
                      <VacantBtn>Vacant</VacantBtn>
                    ) : (
                      <RentedBtn>Rented</RentedBtn>
                    )}
                  </div>
                </div>
                <p>{unitDetails.unit.building_name}</p>
                <p>{unitDetails.unit.address}</p>
                {unitDetails.unit.occupancy_status != "vacant" && (
                  <>
                    <p className="font-bold mt-4">Rent</p>
                    <p>{unitDetails.unit.rent}</p>
                  </>
                )}
              </div>
              <div className="rounded-lg flex justify-center items-center">
                {unitDetails.unit.media && unitDetails.unit.media.length > 0 ? (
                  <CustomImage
                    location={unitDetails.unit.media[0].location} // Directly use the first media location
                    jwt={jwt}
                    initials={getInitials(unitDetails.unit.entity_id)}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div
                    className="text-white text-xl flex items-center justify-center"
                    style={{ width: "100px", height: "100px" }}
                  >
                    <PiBuildingsLight size={100} color="grey" />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Agreement Details */}
          <p className="text-xl font-semibold mb-4">Agreement Details</p>
          <div className="bg-white rounded-lg border-1 p-10">
            {agreement ? (
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1">
                  <p className="flex flex-col">
                    <strong className="text-gray-500">Monthly Rent</strong>
                    {agreementDetails.rent[0].rent}
                  </p>
                  <p className="flex flex-col">
                    <strong className="text-gray-500">Due Date</strong>
                    {calculateDueDate(agreementDetails.due_date)}
                  </p>
                  <p className="flex flex-col">
                    <strong className="text-gray-500">Staying From</strong>
                    {extractDatePart(agreementDetails.staying_from)}
                  </p>
                  <p className="flex flex-col">
                    <strong className="text-gray-500">Security Deposit</strong>
                    {agreementDetails.security_deposit}
                  </p>
                </div>
                <div className="flex-1">
                  <p className="flex flex-col">
                    <strong className="text-gray-500">Maintenance</strong>
                    {agreementDetails.maintenance}
                  </p>
                  <p className="flex flex-col">
                    <strong className="text-gray-500">Tenure</strong>
                    {agreementDetails.tenure}
                  </p>
                  <p className="flex flex-col">
                    <strong className="text-gray-500">Vacating On</strong>
                    {extractDatePart(agreementDetails.vacating_on)}
                  </p>
                </div>
              </div>
            ) : (
              <div className="mb-4 p-10 flex flex-col justify-between items-center">
                You don’t have any tenant right now. Please create your
                agreement.
                <button
                  // onClick={() => setShowAddAgreementModal(true)}
                  onClick={() => navigate("AddAgreement")}
                  className="bg-black text-white w-40 py-2 rounded-md mt-10"
                >
                  Add Agreement
                </button>
                {/* <AddAgreementModal
                  isOpen={showAddAgreementModal}
                  onClose={() => setShowAddAgreementModal(false)}
                  // onAddAgreement={handleAddAgreement}
                  unitDetails={unitDetails}
                /> */}
              </div>
            )}
          </div>
        </div>

        {/* Container for Transactions and Tenant Details */}
        <div className="flex flex-col justify-between rounded-lg p-4">
          {/* Transactions Container */}
          {transactions?.length > 0 && (
            <div className="rounded-lg mb-4">
              <div className="flex justify-between items-center ">
                <p className="text-xl font-semibold">Transactions</p>
                <p>View All</p>
              </div>
              <div className="border bg-white rounded-lg mt-4 p-10">
                <div className="border-b border-gray-500 flex justify-center items-center gap-4 p-2">
                  <p>Active</p>
                  <p>Archive</p>
                </div>
                {transactions && transactions.length > 0 && (
                  <div className="mt-2 flex flex-col gap-5">
                    {transactions.map((transaction, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center"
                      >
                        <div className="flex items-center gap-2">
                          <MonthIcons month={transaction.month} />
                          <div>
                            <p>
                              {transaction.nature} received for{" "}
                              {transaction.month}
                            </p>
                            <p>{transaction.created}</p>
                          </div>
                        </div>
                        <div className="flex flex-col items-center">
                          <p>{transaction.amount}</p>
                          <p>
                            {transaction.payment_status === "success" ? (
                              <CustomButton
                                tailwindClasses="bg-green-500 h-4 text-xs flex justify-center items-center text-white"
                                label="Paid"
                                onClick={() => setIsModalOpen(true)}
                              />
                            ) : (
                              <CustomButton
                                tailwindClasses="bg-blue-500 text-white"
                                label="Pending"
                                onClick={() => setIsModalOpen(true)}
                              />
                            )}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Tenant Details Container */}
          {unitDetails.tenant && (
            <div>
              <p className="text-xl font-semibold ">Tenant Details</p>
              <div className="rounded-lg bg-white p-10 flex flex-col gap-4">
                <div className="flex items-center gap-4">
                  <>
                    {unitDetails.tenant.media &&
                    unitDetails.tenant.media.profile_picture &&
                    unitDetails.tenant.media.profile_picture.location ? (
                      <CustomImage
                        location={
                          unitDetails.tenant.media.profile_picture.location
                        }
                        jwt={jwt}
                        initials={getInitials(
                          unitDetails.tenant.first_name,
                          unitDetails.tenant.last_name
                        )}
                        className="h-20 w-20 rounded-full"
                      />
                    ) : (
                      <div className="h-20 w-20 flex items-center justify-center rounded-full bg-gray-200">
                        {getInitials(
                          unitDetails.tenant.first_name,
                          unitDetails.tenant.last_name
                        )}
                      </div>
                    )}

                    <div className="flex flex-col">
                      <p>{unitDetails.tenant.first_name}</p>
                      <p>{unitDetails.tenant.mobile}</p>
                      <WhatsAppComponent
                        label={"chat"}
                        selectedTenant={tenant}
                      />
                    </div>
                  </>
                </div>
                {/* Conditionally render the button and modal */}
                {tenant && (
                  <CustomButton
                    tailwindClasses="bg-blue-500 text-white"
                    label="See Details"
                    onClick={() => setTenantDetailsModal(true)}
                  />
                )}

                {tenantDetailsModal && unitDetails && (
                  <TenantDetailsModal
                    isOpen={tenantDetailsModal}
                    onClose={() => setTenantDetailsModal(false)}
                    unitDetails={unitDetails}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* row3 */}
      <p className="text-xl font-semibold pl-8 pt-4 pb-4">Unit Details</p>
      <div className="bg-white rounded-lg shadow-md p-10">
        <div className="flex justify-between">
          <div className="w-1/2 flex flex-col gap-2">
            <div>
              <p>{unitDetails.unit.unit_name}</p>
              <p>{unitDetails.unit.address}</p>
            </div>

            <div className="flex justify-between">
              <div>
                <p>Flat Area</p>
                <p>{unitDetails.unit.unit_area}</p>
              </div>
              <div>
                <p>BHK</p>
                <p>{unitDetails.unit.bhk}</p>
              </div>
              <div>
                <p>Furnishing</p>
                <p>{unitDetails.unit.furnishing_status}</p>
              </div>
            </div>
          </div>
          <div>
            {/* <button>Edit</button> */}
            <CustomButton
              tailwindClasses="bg-blue-500 text-white"
              label="Edit"
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        </div>
        <div className="">
          {amenitiesList.length > 0 && (
            <>
              <div className="flex justify-between items-center p-2 bg-gray-100 rounded-md mt-4">
                {amenitiesList.map((amenity, index) => (
                  <li key={index}>{amenity.trim()}</li>
                ))}
              </div>
              <div className="flex flex-wrap gap-4 p-2 rounded-md mt-4">
                {amenitiesList.map((amenity, index) => {
                  const { icon, name } = mapAmenitiesToIcons(amenity);
                  return <Facility key={index} name={name} icon={icon} />;
                })}
              </div>
            </>
          )}
        </div>
      </div>

      {/* Modal for editing unit */}
      <div>
        {isModalOpen && unitDetails && (
          <CustomModalEditUnit
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            unitDetails={unitDetails}
            buildings={buildings}
            onUpdate={handleUpdateSuccess}
          />
        )}
      </div>
    </div>
  );
}

export default UnitDetails;
