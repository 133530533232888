import React, { createContext, useState, useContext, useCallback } from "react";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import { useAuth } from "../../Utility/AuthProvider";
import { useUser } from "../../Utility/UserProvider";

// Create the context
const UnitDetailsContext = createContext();

// Create a provider component
export const UnitDetailsProvider = ({ children }) => {
  //   const { user } = useUser();
  //   console.log("User Data -", user);
  const { jwt } = useAuth();
  const [unitDetails, setUnitDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  //   console.log("JWT - --", jwt);
  // Function to fetch unit details

  // Use useCallback to stabilize fetchUnitDetails
  const fetchUnitDetails = useCallback(async (unitId, jwt) => {
    setLoading(true);
    try {
      const response = await APICall(
        "GET",
        paths.unit.view,
        undefined,
        { unit_id: unitId },
        jwt
      );
      setUnitDetails(response.response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Function to update unit details
  const updateUnitDetails = async (updatedDetails, jwt) => {
    setLoading(true);
    try {
      const response = await APICall(
        "PUT",
        paths.unit.update,
        updatedDetails,
        undefined,
        jwt
      );
      setUnitDetails(response.response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <UnitDetailsContext.Provider
      value={{
        unitDetails,
        loading,
        error,
        fetchUnitDetails,
        updateUnitDetails,
      }}
    >
      {children}
    </UnitDetailsContext.Provider>
  );
};

// Custom hook to use the context
export const useUnitDetails = () => useContext(UnitDetailsContext);
