import React, { useState } from "react";
import CustomSidebar from "./CustomSidebar";
import Navbar from "./Navbar";
import { Outlet, useLocation } from "react-router-dom";

const Layout = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  // Define routes where the sidebar and navbar should not be displayed
  const hiddenRoutes = ["/AddInfo"];

  // Check if the current route matches any hidden route
  const isHidden = hiddenRoutes.includes(location.pathname);

  return (
    <div className="flex h-screen">
      {!isHidden && (
        <CustomSidebar collapsed={collapsed} toggleSidebar={toggleSidebar} />
      )}
      <div
        className={`flex flex-col flex-1 ${
          !isHidden ? (collapsed ? "ml-20" : "ml-64") : ""
        } transition-all duration-300`}
      >
        {!isHidden && (
          <div
            style={{
              background:
                "linear-gradient(241deg, rgba(0,133,255,1) 0%, rgba(13,10,150,1) 35%)",
            }}
          >
            <Navbar />
          </div>
        )}
        <div className="flex flex-col flex-1 p-4 bg-gray-100 overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
