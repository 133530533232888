import React from "react";

function PrivacyPolicy() {
  return (
    <div className="container mx-auto px-4 py-8">
      {/* Use Tailwind CSS classes for styling */}
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <h3 className="py-4">Welcome to RentPe</h3>{" "}
      <p className="mb-4">
        RentPe is the ultimate platform for live streaming and social
        connection, where subscribers can support and engage with creators. This
        privacy policy applies to subscribers, creators, and all users of our
        platform, and is part of our Terms of Use. By using our platform, you
        agree that your personal information that you provide directly to us or
        that we collect through your use of the platform, may be transferred to
        and stored in the United States and handled as described in this Policy.
      </p>
      <h3 className="text-xl font-medium mb-1">
        Information You Provide Through Your Account
      </h3>
      <p className="mb-4">
        This is information that you provide to us through text fields, such as
        your name, payment information and benefits. The information we collect
        differs depending on if you make an account, become a subscriber, or
        become a creator.
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>Full Name</li>
        <li>Email Address</li>
        <li>Mobile Number</li>
        <li>Username / Instagram Username</li>
        <li>Mobile Number</li>
        <li>PAN Number</li>
        <li>Aadhaar Number</li>
        <li>Bank Account Details</li>

        {/* ... other information based on user type (landlord/tenant) ... */}
      </ul>
      <p className="mb-4">
        You may also sign up using a Facebook, Instagram or Google account. We
        will ask permission to access basic information from your Facebook,
        Instagram or Google account, such as your name, email, and profile
        picture. You can choose to stop sharing that information with us at any
        time by going to Facebook or Google to remove RentPe’s access to that
        account and your ability to log in.
      </p>
      <h3 className="text-xl font-medium mb-1">Subscribers or Fans</h3>
      <p className="mb-4">
        A subscriber is someone who joins RentPe’s platform to support a
        creator’s content. You may have to make payment on our platform to avail
        the benefits or purchase any services offered by a creator. We assure
        you that the payments get processed by most trusted third party payment
        gateways. We collect and process information about the creators you
        support, the level at which you support them, what benefits you receive
        and how often you support them.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Creators or Artists</h2>
      <p className="mb-4">
        A creator is someone who creates and provides content for their
        subscribers through RentPe’s platform. To become a creator, you must
        sign up on RentPe App. To receive payouts you have to provide us your
        preferred payment mode with your details e.g. UPI / Bank Transfer etc.
      </p>
      <p>
        You must also provide us with additional information for tax purposes.
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>Legal Name</li>
        <li>Address Proof</li>
        <li>PAN Card/Aadhar Card</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-2">
        Additional Information We Collect
      </h2>
      <p>
        We collect information automatically as you navigate the site or through
        our third party analytics providers. We may store usage information such
        as the type of device you use to access RentPe, your operating system,
        browser type, IP address, and device ID, the pages you visit or request,
        links clicked, referring sites, user interactions and your search terms.
        We also derive your location from your self-disclosed country, your IP
        address.
      </p>
      <p className="mb-4">
        We also collect the information from your interaction with our platform
        in terms of user comments, services browsed etc.
      </p>
      <h2 className="text-xl font-medium mb-1">How We Use Your Information</h2>
      <p>We process your information to:</p>
      <ul className="list-disc ml-6 mb-4">
        <li>provide RentPe services to you</li>
        <li>allow you to sign in to your account</li>
        <li>allow you to join a creator’s membership program on RentPe</li>
        <li>process a creator’s membership payments</li>
        <li>
          send you emails relevant to your usage, as controlled by your email
          preferences
        </li>
        <li>reply to your questions</li>
        <li>market RentPe to you</li>
        <li>
          understand how you use the service and create better tools for
          creators to serve subscribers
        </li>
        <li>
          conduct research and development to improve RentPe and develop future
          products
        </li>
        <li>prevent fraud and abuse on RentPe</li>
        <li>ship merchandise to you if a benefit includes it</li>
        <li>
          allow us to provide you with reasonable accommodation, if you notify
          us of a disability
        </li>
      </ul>
      <h2 className="text-xl font-medium mb-1 pt-4">Information Sharing</h2>
      <p>
        We share the sensitive personal information to any third party without
        obtaining the prior consent of the user in the following limited
        circumstances:
      </p>
      <p>
        (a) When it is requested or required by law or by any court or
        governmental agency or authority to disclose, for the purpose of
        verification of identity, or for the prevention, detection,
        investigation including cyber incidents, or for prosecution and
        punishment of offences. These disclosures are made in good faith and
        belief that such disclosure is reasonably necessary for enforcing these
        Terms; for complying with the applicable laws and regulations.
      </p>
      <p className="pb-2">Information We Share with Creators</p>
      <ul className="list-disc ml-6 mb-4">
        <li>your name and other profile information you’ve provided</li>
        <li>any messages you send creators through RentPe</li>
        <li>
          some aggregated and anonymized data about how you use RentPe that
          cannot be linked back to you or to any individual user
        </li>
      </ul>
      <h2 className="text-xl font-medium mb-1 pt-4">Information Security</h2>
      <p>
        We take appropriate security measures to protect against unauthorized
        access to or unauthorized alteration, disclosure or destruction of data.
        These include internal reviews of our data collection, storage and
        processing practices and security measures, including appropriate
        encryption and physical security measures to guard against unauthorized
        access to systems where we store personal data.
      </p>
      <p>
        All information gathered on our Website is securely stored within our
        controlled database. The database is stored on servers secured behind a
        firewall; access to the servers is password-protected and is strictly
        limited. However, as effective as our security measures are, no security
        system is impenetrable. We cannot guarantee the security of our
        database, nor can we guarantee that information you supply will not be
        intercepted while being transmitted to us over the Internet. And, of
        course, any information you include in a posting to the discussion areas
        is available to anyone with Internet access.
      </p>
      <p>
        However the internet is an ever evolving medium. We may change our
        Privacy Policy from time to time to incorporate necessary future
        changes. Of course, our use of any information we gather will always be
        consistent with the policy under which the information was collected,
        regardless of what the new policy may be.
      </p>
      <p>
        We comply with industry standards, including SOC 2, ISO 27001, and other
        relevant security frameworks to protect your data.
      </p>
      <h2 className="text-xl font-medium mb-1 pt-4">Cookies</h2>
      <p>
        To improve the responsiveness of the sites for our users, we may use
        "cookies", or similar electronic tools to collect information to assign
        each visitor a unique, random number as a User Identification (User ID)
        to understand the user's individual interests using the Identified
        Computer. Unless you voluntarily identify yourself (through
        registration, for example), we will have no way of knowing who you are,
        even if we assign a cookie to your computer. The only personal
        information a cookie can contain is information you supply (an example
        of this is when you ask for our Personalised Horoscope). A cookie cannot
        read data off your hard drive. Our advertisers may also assign their own
        cookies to your browser (if you click on their ads), a process that we
        do not control.
      </p>
      <p>
        Our web servers automatically collect limited information about your
        computer's connection to the Internet, including your IP address, when
        you visit our site. (Your IP address is a number that lets computers
        attached to the Internet know where to send you data -- such as the web
        pages you view.) Your IP address does not identify you personally. We
        use this information to deliver our web pages to you upon request, to
        tailor our site to the interests of our users, to measure traffic within
        our site and let advertisers know the geographic locations from where
        our visitors come.
      </p>
      <h2 className="text-2xl font-semibold mb-2 pt-4">Grievance Redressal</h2>
      <p>
        Redressal Mechanism: Any complaints, abuse or concerns with regards to
        content and or comment or breach of these terms shall be immediately
        informed to the designated Grievance Officer as mentioned below via in
        writing or through email signed with the electronic signature to the
        “Grievance Officer”
      </p>
      <p>Mr. Shailesh (Grievance Officer) https://www.rentpe.club/</p>
      <p>RentPe, 300 Delaware Avenue, STE 210 400, Wilmington, DE 19801</p>
      <p>Email:support@rentpe.club</p>
      <p>Ph: +1-30291017</p>
    </div>
  );
}

export default PrivacyPolicy;
