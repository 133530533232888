import React, { createContext, useState } from "react";

export const AgreementContext = createContext();

export const AgreementProvider = ({ children }) => {
  const [agreementData, setAgreementData] = useState({
    unitId: null,
    agreementDetails: {},
    tenants: [],
  });

  const addAgreementDetails = (details) => {
    setAgreementData((prev) => ({
      ...prev,
      unitId: details.unitId,
      agreementDetails: details,
    }));
  };

  const addTenant = (tenant) => {
    setAgreementData((prev) => ({
      ...prev,
      tenants: [...prev.tenants, tenant],
    }));
  };

  const resetAgreementData = () => {
    setAgreementData({
      unitId: null,
      agreementDetails: {},
      tenants: [],
    });
  };

  return (
    <AgreementContext.Provider
      value={{
        agreementData,
        addAgreementDetails,
        addTenant,
        resetAgreementData,
      }}
    >
      {children}
    </AgreementContext.Provider>
  );
};
