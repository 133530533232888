import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import Logo from "../assets/Group 1261153494.png";
import HomeIcon from "../assets/SideBarIcons/Home.png";
import PaymentsIcon from "../assets/SideBarIcons/Payments.png";
import TenantsIcon from "../assets/SideBarIcons/Tenants.png";
import PropertiesIcon from "../assets/SideBarIcons/Properties.png";
import RentIcon from "../assets/SideBarIcons/Rent.png";
import AgreementsIcon from "../assets/SideBarIcons/Agreements.png";
import ReportsIcon from "../assets/SideBarIcons/Reports.png";
import SettingsIcon from "../assets/SideBarIcons/Settings.png";
import { NavLink, Link } from "react-router-dom";

const iconStyle = "w-6 h-6 mr-2";

export default function CustomSidebar({ collapsed, toggleSidebar }) {
  return (
    <div
      className={`h-full ${
        collapsed ? "w-20" : "w-64"
      } bg-gradient-to-b from-blue-500 to-blue-800 text-white fixed top-0 left-0 transition-all duration-300`}
    >
      <div className="p-4 flex items-center justify-between">
        {!collapsed && <img src={Logo} alt="Logo" className="mb-5 h-12 w-32" />}
        <button onClick={toggleSidebar} className="text-white">
          <FaBars />
        </button>
      </div>
      <div className="p-4">
        <NavLink
          to={"/"}
          end
          className={({ isActive }) =>
            `flex items-center cursor-pointer mb-4 
    ${isActive ? "text-green-500" : "text-white"}`
          }
        >
          {({ isActive }) => (
            <>
              <img
                src={HomeIcon}
                alt="Home Icon"
                className={`${iconStyle} ${
                  isActive ? "filter-green" : "filter-white"
                }`}
              />
              {!collapsed && <span>Home</span>}
            </>
          )}
        </NavLink>

        <NavLink
          to={"/Payments"}
          end
          className={({ isActive }) =>
            `flex items-center cursor-pointer mb-4 
    ${isActive ? "text-green-500" : "text-white"}`
          }
        >
          <img src={PaymentsIcon} alt="Payments Icon" className={iconStyle} />
          {!collapsed && <span>Payments</span>}
        </NavLink>

        <NavLink
          to={"/MyProperties/TenantsList"}
          end
          className={({ isActive }) =>
            `flex items-center cursor-pointer mb-4 
    ${isActive ? "text-green-500" : "text-white"}`
          }
        >
          <img src={TenantsIcon} alt="Tenants Icon" className={iconStyle} />
          {!collapsed && <span>Tenants</span>}
        </NavLink>

        <NavLink
          to={"/MyProperties"}
          end
          className={({ isActive }) =>
            `flex items-center cursor-pointer mb-4 
    ${isActive ? "text-green-500" : "text-white"}`
          }
        >
          <img
            src={PropertiesIcon}
            alt="Properties Icon"
            className={iconStyle}
          />
          {!collapsed && <span>Properties</span>}
        </NavLink>

        <NavLink
          to={"/Agreements"}
          end
          className={({ isActive }) => `flex items-center cursor-pointer mb-4 
          ${isActive ? "text-green-500" : "text-white"}`}
        >
          <img
            src={AgreementsIcon}
            alt="Agreements Icon"
            className={iconStyle}
          />
          {!collapsed && <span>Agreements</span>}
        </NavLink>

        <NavLink
          to={"/Reports"}
          end
          className={({ isActive }) => `flex items-center cursor-pointer mb-4 
          ${isActive ? "text-green-500" : "text-white"}`}
        >
          <img src={ReportsIcon} alt="Reports Icon" className={iconStyle} />
          {!collapsed && <span>Reports</span>}
        </NavLink>
      </div>

      <footer className="absolute bottom-0 p-4 mt-auto w-full text-center">
        <p className="pb-4">
          <Link to="/privacy-policy">Privacy Policy</Link>
          {/* Use Link component */}
        </p>
        {!collapsed && <span>© 2024 RentPe. All rights reserved.</span>}
      </footer>
    </div>
  );
}
