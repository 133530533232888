import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import CircularInitial from "../UpcomingRent/CircularInitial";
import { MdDeleteForever } from "react-icons/md";
import { TenantContext } from "../Tenants/TenantContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

export default function TenantActionModal({
  open,
  handleClose,
  data,
  handleAddAnother,
}) {
  const navigate = useNavigate();
  const { resetTenantData, deleteTenant, tenantData } =
    useContext(TenantContext); // Access reset function
  console.log("Tenant Action Modal Data -", data);

  //   const handleAddMoreTenant = () => {
  //     navigate("AddNewTenant");
  //   };

  const handleDelete = (tenantId) => {
    deleteTenant(tenantId);
    handleClose(); // Close modal after deletion
  };

  const handleContinue = () => {
    // navigate("/AgreementOverview");
    navigate("/AgreementOverview", { state: { tenantData } });
    handleClose(); // Close the modal after continuing
  };

  console.log(
    "Tenant Data on TenantActionModal via AddNewTenant props 😀 -",
    data
  );

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
      <Box sx={style}>
        <h2 id="modal-title">Tenant List</h2>
        <p className="text-xs">You can add multiple tenants in single flat</p>

        <div className="flex gap-4 m-8">
          <div className="text-xs flex flex-col items-start gap-2">
            {/* <p>{tenantData.name}</p>
            <p>{tenantData.mobile}</p> */}
            {data.map((tenant, index) => (
              <div key={tenant.entity_id} className="flex items-center gap-2">
                {/* Key added for each item */}
                <div className="flex items-center">
                  {/* Container for Part 1 & 2 (flex-row) */}
                  <CircularInitial firstName={tenant.name} /> {/* Part 1 */}
                  <div className="flex flex-col items-start ml-2">
                    {/* Container for Part 2 (flex-col), added margin-left */}
                    <span>
                      {tenant.first_name} {tenant.last_name}
                    </span>
                    <span>{tenant.mobile}</span>
                  </div>
                  {/* End of Part 2 */}
                </div>
                {/* End of Part 1 & 2 container */}
                <div className="ml-4">
                  {/* Part 3 container, added margin-left */}
                  {index === 0 ? "(Primary)" : "(Secondary)"}
                  {/* Part 3 content */}
                </div>
                {/* End of Part 3 container */}
                <button onClick={() => handleDelete(tenant.entity_id)}>
                  <MdDeleteForever size={20} style={{ float: "right" }} />
                </button>
              </div>
            ))}
          </div>
        </div>
        <Link
          component="button"
          variant="body2"
          onClick={handleAddAnother}
          sx={{ marginBottom: 2 }}
        >
          + Add Another Tenant
        </Link>
        <Button
          variant="contained"
          color="primary"
          onClick={handleContinue}
          sx={{ width: "100%" }}
        >
          Continue
        </Button>
      </Box>
    </Modal>
  );
}
