import React, { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../Buttons/CustomButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getOrdinalSuffix } from "../../Utility/Utils"; // Adjust the import path as necessary
import { addMonths } from "date-fns"; // Import date-fns to handle date manipulation
import { AgreementContext } from "./AgreementContext";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import { useAuth } from "../../Utility/AuthProvider";

const AddAgreement = () => {
  const navigate = useNavigate();
  const { unitId } = useParams(); // Get the unitId from the URL
  const { addAgreementDetails } = useContext(AgreementContext); // Use the context
  const { jwt } = useAuth();

  const [monthlyRent, setMonthlyRent] = useState("");
  const [stayingFrom, setStayingFrom] = useState(new Date());
  const [dueDate, setDueDate] = useState("");
  const [securityDeposit, setSecurityDeposit] = useState("");
  const [maintenanceCharge, setMaintenanceCharge] = useState("");
  const [advanceRent, setAdvanceRent] = useState(false);
  const [tenure, setTenure] = useState(null);
  const [vacatingDate, setVacatingDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleTenureClick = (value) => {
    if (value === "custom") {
      setShowDatePicker(true);
      setTenure(value); // Set tenure to 'custom' to apply custom styling
    } else {
      setTenure(value);
      setShowDatePicker(false); // Hide date picker for non-custom tenures
    }
  };

  const handleDateChange = (date) => {
    setVacatingDate(date);
    setShowDatePicker(false); // Hide the date picker after selecting a date
    setTenure("custom"); // Ensure tenure is set to custom when a date is chosen
  };

  const calculateVacatingDate = () => {
    if (tenure === "custom" && vacatingDate) {
      return vacatingDate;
    }
    if (!stayingFrom || !tenure || tenure === "custom") return null;
    return addMonths(stayingFrom, tenure); // Calculate end date based on stayingFrom and tenure
  };

  const vacatingDateCalculated = calculateVacatingDate();

  const handleContinue = async (e) => {
    e.preventDefault();

    // Extract the day from the selected date
    const dayOfMonth = new Date(dueDate).getDate();
    const formattedDueDate = dayOfMonth.toString(); // Convert to string (e.g., "15")

    // Collect form data
    const agreementDetails = {
      unitId,
      monthlyRent,
      stayingFrom,
      formattedDueDate,
      securityDeposit,
      maintenanceCharge,
      advanceRent,
      tenure,
      vacatingDate: tenure === "custom" ? vacatingDate : vacatingDateCalculated,
    };

    console.log("Agreement Form Data:", agreementDetails); // Log form data

    try {
      // Check for existing agreements
      const body = {
        agreement_id: agreementDetails?.entity_id ?? "", // Use existing agreement ID if available
        unit_id: unitId, // Use the unit ID from the form
        staying_from: stayingFrom, // Use the stayingFrom date from the form
        vacating_on:
          tenure === "custom" ? vacatingDate : vacatingDateCalculated, // Use the vacating date from the form
      };

      const response = await APICall(
        "POST",
        paths.agreement.coincide, // Replace with your API endpoint
        body,
        undefined,
        jwt
      );

      console.log("Agreement Coincide Response:", response);

      if (response.coincides) {
        // If an existing agreement is found, prevent the user from proceeding
        alert(
          "An existing agreement overlaps with the selected dates. Please choose different dates."
        );
        return;
      }

      // If no existing agreement is found, proceed
      addAgreementDetails(agreementDetails); // Save agreement details to context
      navigate(`/MyProperties/${unitId}/AddAgreement/AddNewTenant`); // Navigate to the next page
    } catch (error) {
      console.error("Error checking agreement coincide:", error);
      alert(
        "An error occurred while checking for existing agreements. Please try again."
      );
    }
  };

  const toggleAdvanceRent = () => {
    setAdvanceRent((prev) => !prev);
  };

  return (
    <form onSubmit={handleContinue} className="min-h-screen w-full p-6">
      <div className="container mx-auto max-w-4xl">
        <h2 className="text-xl font-semibold mb-4">New Agreement</h2>
        <div>
          {/* <p>{unitDetails?.unit.unit_name}</p> */}
          {/* <p>{unitDetails?.unit.address}</p> */}
        </div>

        <div className="mt-4 relative">
          <label
            htmlFor="monthlyRent"
            className="block text-sm font-medium text-gray-700"
          >
            Monthly Rent Amount
            <span className="text-red-500 ml-1">*</span>
          </label>
          <input
            id="monthlyRent"
            type="number"
            required
            value={monthlyRent}
            onChange={(e) => setMonthlyRent(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        {/* Staying From Date */}
        <div>
          <label
            htmlFor="stayingFrom"
            className="block text-sm font-medium text-gray-700"
          >
            Staying From
          </label>
          <input
            id="stayingFrom"
            type="date"
            required
            value={stayingFrom}
            onChange={(e) => setStayingFrom(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        {/* Due Date */}
        <div>
          <label
            htmlFor="dueDate"
            className="block text-sm font-medium text-gray-700"
          >
            Due Date
          </label>
          <input
            id="dueDate"
            type="date"
            required
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
          {dueDate && (
            <p className="pt-2">{`Due on the ${getOrdinalSuffix(
              new Date(dueDate).getDate()
            )} of every month`}</p>
          )}
        </div>

        {/* Security Deposit */}
        <div className="mt-4 relative">
          <label
            htmlFor="securityDeposit"
            className="block text-sm font-medium text-gray-700"
          >
            Security Deposit
            <span className="text-red-500 ml-1">*</span>
          </label>
          <input
            id="securityDeposit"
            type="number"
            required
            value={securityDeposit}
            onChange={(e) => setSecurityDeposit(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        {/* Maintenance Charge */}
        <div className="mt-4 relative">
          <label
            htmlFor="maintenanceCharge"
            className="block text-sm font-medium text-gray-700"
          >
            Maintenance Charge
            <span className="text-red-500 ml-1">*</span>
          </label>
          <input
            id="maintenanceCharge"
            type="number"
            required
            value={maintenanceCharge}
            onChange={(e) => setMaintenanceCharge(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        {/* Advance Rent Toggle */}
        <div className="text-xs flex flex-col items-start justify-between">
          <div className="w-full mt-4 flex items-center justify-between">
            <label htmlFor="advanceRent" className="block text-gray-700 mr-2">
              Advance Rent
            </label>
            <div
              onClick={toggleAdvanceRent}
              className={`w-10 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${
                advanceRent ? "bg-green-500" : ""
              }`}
            >
              <div
                className={`bg-white w-4 h-4 rounded-full shadow-md transform ${
                  advanceRent ? "translate-x-4" : ""
                }`}
              />
            </div>
          </div>
          <p className="">
            The rent will be collected in advance for the month of the agreement
          </p>
        </div>

        {/* Tenure Selection */}
        <div className="mt-4 grid gap-4">
          <label className="block text-sm font-medium text-gray-700 mb-2 col-span-full">
            Tenure
          </label>
          <div className="grid grid-cols-2 gap-2 col-span-full">
            {[3, 6, 11].map((months) => (
              <button
                key={months}
                type="button"
                onClick={() => handleTenureClick(months)}
                className={`px-4 py-2 rounded-md ${
                  tenure === months ? "bg-blue-500 text-white" : "bg-gray-200"
                }`}
              >
                {months} Months
              </button>
            ))}
            <button
              type="button"
              onClick={() => handleTenureClick("custom")}
              className={`px-4 py-2 rounded-md ${
                tenure === "custom" ? "bg-blue-500 text-white" : "bg-gray-200"
              }`}
            >
              Custom
            </button>
          </div>
          {tenure && (tenure !== "custom" || vacatingDate) && (
            <p className="mt-2 col-span-full">
              Vacating on:{" "}
              {tenure === "custom" && vacatingDate
                ? new Date(vacatingDate).toDateString()
                : vacatingDateCalculated
                  ? new Date(vacatingDateCalculated).toDateString()
                  : "Not set"}
            </p>
          )}
          {tenure === "custom" && showDatePicker && (
            <input
              type="date"
              value={vacatingDate}
              onChange={(e) => handleDateChange(e.target.value)}
              className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
            />
          )}
        </div>

        {/* Submit Button */}
        <div className="flex justify-between items-center mt-4">
          <CustomButton
            tailwindClasses={`bg-blue-500 text-white ${
              !monthlyRent || !securityDeposit || !maintenanceCharge
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            label="Continue to Add Tenant"
            onClick={handleContinue}
            disabled={!monthlyRent || !securityDeposit || !maintenanceCharge}
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default AddAgreement;
