import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../Utility/AuthProvider";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import GenericTable from "../GenericTable";
import loaderAnimation from "../../assets/animations/loader.json";
import FilterBuildingModal from "./FilterBuildingModal";
import { useSearchParams } from "react-router-dom";

function Units({ building }) {
  const { jwt } = useAuth();
  const [allUnits, setAllUnits] = useState([]);
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showBuildingModal, setShowBuildingModal] = useState(false);
  const [selectedBuildingId, setSelectedBuildingId] = useState(null);
  const [filterBuildingModalVisible, setFilterBuildingModalVisible] =
    useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const buildingId = searchParams.get("buildingId");
  const [buildingData, setBuildingData] = useState([]);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        setLoading(true);
        let apiUrl = paths.unit.fetch;
        if (buildingId) {
          apiUrl += `?building_id=${buildingId}`;
        }
        const unitResponse = await APICall(
          "GET",
          apiUrl,
          undefined,
          undefined,
          jwt
        );
        setAllUnits(unitResponse.response);
      } catch (error) {
        console.error("Error fetching units:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUnits();
  }, [jwt, buildingId]);

  useEffect(() => {
    const fetchBuildings = async () => {
      const buildingResponse = await APICall(
        "GET",
        paths.building.fetch,
        undefined,
        undefined,
        jwt
      );
      setBuildingData(buildingResponse.response);
    };
    fetchBuildings();
  }, [jwt]);

  // Filter units based on the selected building
  useEffect(() => {
    if (selectedBuildingId) {
      const filtered = allUnits.filter(
        (unit) => unit.building_id === selectedBuildingId
      );
      setFilteredUnits(filtered);
    } else {
      setFilteredUnits(allUnits);
    }
  }, [allUnits, selectedBuildingId]);

  const handleUnitsTenant = (unit) => {
    navigate(`/UnitsTenant/${unit.unit_name}`, { state: unit });
  };

  const handleEditUnitClick = (unit) => {
    navigate(`/MyProperties/${unit.unit_name}`, { state: unit });
  };

  const handleOpenBuildingModal = () => {
    setShowBuildingModal(true);
  };

  const handleCloseBuildingModal = () => {
    setShowBuildingModal(false);
  };

  const handleApplyBuildingFilter = (buildingId) => {
    setSelectedBuildingId(buildingId);

    if (!buildingId) {
      setFilteredUnits(allUnits); // Display all units if no filter
    } else {
      setFilteredUnits(
        allUnits.filter((unit) => unit.building_id === buildingId)
      );
    }
  };

  // Prepare the columns and rows for the GenericTable
  const columns = [
    { id: "serialNo", label: "Record No." },
    { id: "unitName", label: "Unit Name" },
    { id: "buildingName", label: "Building Name" },
    { id: "address", label: "Address" },
    { id: "furniture", label: "Furniture" },
    { id: "bhk", label: "BHK" },
    { id: "status", label: "Status" },
  ];

  const rows = filteredUnits.map((unit, index) => ({
    serialNo: index + 1,
    unitName: unit.unit_name,
    buildingName: unit.building_name,
    address: unit.address,
    furniture: unit.furnishing_status,
    bhk: unit.bhk,
    status: unit.rent,
    onClick: () => handleEditUnitClick(unit), // Add the navigation function here
  }));

  const clearFilter = () => {
    setSelectedBuildingId(null); // Clear selected building
    setFilteredUnits(allUnits); // Reset to show all units
  };

  return (
    <div className="border-2 p-10 rounded-2xl">
      {/* Filter Building and Clear Filter Buttons */}
      <div className="flex items-center justify-end space-x-4 mb-4">
        <button
          onClick={() => setFilterBuildingModalVisible(true)}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Filter by Building
        </button>
        <button
          onClick={clearFilter}
          disabled={!selectedBuildingId} // Disable if no filter is applied
          className={`px-4 py-2 rounded ${
            selectedBuildingId
              ? "bg-gray-500 text-white hover:bg-gray-600"
              : "bg-gray-300 text-gray-500 cursor-not-allowed"
          }`}
        >
          Clear Filter
        </button>
      </div>

      {/* Conditionally Render Table or No Units Message */}
      {filteredUnits.length > 0 ? (
        <GenericTable
          columns={columns}
          rows={rows}
          rowsPerPageOptions={[10, 25, 50]}
          loading={loading}
          loaderAnimation={loaderAnimation}
          onRowClick={(row) => row.onClick && row.onClick()} // Handle row click
        />
      ) : (
        <div className="text-center mt-4 text-gray-600">
          No units found for the selected building.
        </div>
      )}

      <FilterBuildingModal
        show={filterBuildingModalVisible}
        handleClose={() => setFilterBuildingModalVisible(false)}
        buildings={building} // Pass the list of buildings
        selectedBuildingId={selectedBuildingId}
        handleApply={handleApplyBuildingFilter}
      />
    </div>
  );
}

export default Units;

// <div>
//   <div className="banner-container flex justify-start items-center gap-2 mb-4 ml-2 pl-2">
//     <button
//       style={{
//         border: "1px",
//         borderStyle: "solid",
//         padding: "4px",
//         borderRadius: "10px",
//         display: "flex",
//         alignItems: "center",
//         gap: "2px",
//         textAlign: "center",
//         color: selectedBuilding ? "blue" : "grey",
//         borderColor: selectedBuilding ? "blue" : "grey",
//       }}
//       type="dropdown"
//       onClick={() => setShowModal(true)}
//     >
//       Buildings <IoIosArrowDropdown />
//     </button>
//     <FilterBuildingModal
//       show={showModal}
//       handleClose={() => setShowModal(false)}
//       buildings={buildings}
//       handleApply={handleApplyBuildingSelect}
//     />

//     <button
//       style={{
//         border: "1px",
//         borderStyle: "solid",
//         padding: "4px",
//         borderRadius: "10px",
//         display: "flex",
//         alignItems: "center",
//         gap: "2px",
//         textAlign: "center",
//         color: showVacant ? "blue" : "grey",
//         borderColor: showVacant ? "blue" : "grey",
//       }}
//       onClick={handleVacantFilterToggle}
//     >
//       {showVacant ? (
//         <>
//           Vacant <IoIosCloseCircle color="blue" />
//         </>
//       ) : (
//         "Vacant"
//       )}
//     </button>

//     <button
//       style={{
//         border: "1px solid red",
//         padding: "4px",
//         borderRadius: "10px",
//         display: "flex",
//         alignItems: "center",
//         gap: "2px",
//         textAlign: "center",
//         color: "red",
//       }}
//       onClick={() => {
//         setSelectedBuilding(null);
//         setShowVacant(false);
//         setFilteredUnits(allUnits);
//       }}
//     >
//       Clear Filters
//     </button>
//   </div>

//   <div className="units-grid grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
//     {filteredUnits.length > 0 ? (
//       filteredUnits.map((unit) => (
//         <div
//           onClick={() => handleEditUnitClick(unit)}
//           key={unit.entity_id}
//           className="unit-details p-4 border border-black rounded flex flex-col items-start gap-4 cursor-pointer"
//         >
//           <div className="flex justify-start items-center gap-2 w-full">
//             {unit.media && unit.media.length > 0 ? (
//               <CustomImage
//                 location={unit.media[0].location}
//                 jwt={jwt}
//                 initials={getInitials(unit.building_name)}
//               />
//             ) : (
//               <div
//                 className="unit-initials bg-green-500 text-white text-xl flex items-center justify-center rounded-full"
//                 style={{ width: "100px", height: "100px" }}
//               >
//                 {getInitials(unit.unit_name)}
//               </div>
//             )}
//             <div style={{ fontSize: "16px", flex: 1 }}>
//               <div className="flex items-center justify-between">
//                 <div className="flex items-center">
//                   <p>{unit.unit_name}</p>
//                   <p>
//                     {unit.occupancy_status === "rented" ? (
//                       <RentedBtn>
//                         <p>Rented</p>
//                       </RentedBtn>
//                     ) : (
//                       <VacantBtn>
//                         <p>Vacant</p>
//                       </VacantBtn>
//                     )}
//                   </p>
//                 </div>
//                 <div className="">
//                   <p>₹{unit.rent}</p>
//                 </div>
//               </div>
//               <p>{unit.building_name}</p>
//               <p>{unit.address}</p>
//               <div className="flex items-center gap-2">
//                 <div className="flex items-center gap-2">
//                   <FaHouse />
//                   <p>{unit.bhk} BHK</p>
//                 </div>
//                 <div className="flex items-center gap-2">
//                   <MdChair />
//                   <p>{unit.furnishing_status}</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ))
//     ) : (
//       <p>No Units</p>
//     )}

//     {/* Add New Unit Button */}
//     <div
//       className="unit-details add-new-unit-btn p-4 border border-black rounded flex items-center justify-center cursor-pointer"
//       onClick={handleAddNewUnit}
//     >
//       <CiSquarePlus size={100} color="green" />
//     </div>
//   </div>
// </div>
// </Layout>
