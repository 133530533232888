import React, { createContext, useState } from "react";

export const TenantContext = createContext();

export const TenantProvider = ({ children }) => {
  const [tenantData, setTenantData] = useState([]);

  const addTenant = (tenant) => {
    setTenantData((prev) => [...prev, tenant]);
  };

  const deleteTenant = (tenantId) => {
    setTenantData((prev) => prev.filter((t) => t.id !== tenantId));
  };

  const resetTenantData = () => {
    setTenantData([]);
  };

  return (
    <TenantContext.Provider
      value={{ tenantData, addTenant, resetTenantData, deleteTenant }}
    >
      {children}
    </TenantContext.Provider>
  );
};
