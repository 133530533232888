import React, { useEffect, useState } from "react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CircularMeter from "./CircularMeter";
import "./rentincome.css";
import APICall from "../../Utility/APICall";
import { useAuth } from "../../Utility/AuthProvider";
import { paths } from "../../Utility/Constants";

export function RentCards({ label, value, color }) {
  return (
    <div className="flex flex-col items-center bg-white p-10 cursor-pointer rounded-md shadow-md hover:opacity-75">
      <p className="text-black font-bold text-md">{label}</p>
      <p className={`text-lg font-semibold ${color}`}>{value}</p>
    </div>
  );
}

export default function RentIncome() {
  const { jwt } = useAuth();
  const [data, setData] = useState([]);
  const [chartCounter, setChartCounter] = useState(0);
  const [previousMonthData, setPreviousMonthData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const overviewResponse = await APICall(
          "GET",
          paths.rent.overview,
          undefined,
          undefined,
          jwt
        );

        if (
          !overviewResponse.response ||
          !overviewResponse.response.rent_overview
        ) {
          throw new Error("Invalid response structure for rent overview"); // Handle unexpected response format
        }
        const overviewData = overviewResponse.response.rent_overview;
        setData(overviewData);
        console.log("Overview Data:", overviewData);

        // const previousMonthResponse = await APICall(
        //   "GET",
        //   paths.rent.previousMonthOverview,
        //   undefined,
        //   undefined,
        //   jwt
        // );

        // if (
        //   !previousMonthResponse.response ||
        //   !previousMonthResponse.response.rent_overview
        // ) {
        //   throw new Error(
        //     "Invalid response structure for previous month rent overview"
        //   ); // Handle unexpected response format
        // }

        // const previousMonthData = previousMonthResponse.response.rent_overview;
        // setPreviousMonthData(previousMonthData);
        // console.log("Previous Month API Response:", previousMonthResponse);
      } catch (error) {
        console.error(`Error fetching rent data:`, error); // Generic error message

        if (
          error.message &&
          error.message.includes("Invalid response structure")
        ) {
          console.error("API Response Structure Error:", error.message); // More specific message for structure errors
        } else if (error.response) {
          console.error("API Error Details:", error.response.data);
          console.error("API Error Status:", error.response.status);
          console.error("API Error Headers:", error.response.headers);
        } else if (error.request) {
          console.error("Request Error:", error.request);
        } else {
          console.error("General Error:", error.message);
        }
      }
    };

    fetchData();
  }, []);

  function handleOnPressNext() {
    if (chartCounter === data.length - 1) return;
    setChartCounter(chartCounter + 1);
  }

  function handleOnPressPrev() {
    if (chartCounter === 0) return;
    setChartCounter(chartCounter - 1);
  }

  function calculatePercentageChange(currentRent, previousRent) {
    if (previousRent === 0) return "--";
    const percentageChange =
      ((currentRent - previousRent) / previousRent) * 100;
    return percentageChange.toFixed(2) + "%";
  }

  return (
    <div className="border p-8 flex justify-center border-gray-300 shadow-lg rounded-lg">
      {data && data[chartCounter] && (
        <>
          <div className="flex flex-col justify-between">
            <div className="flex flex-col">
              <div className="flex justify-between">
                <button
                  style={{ color: chartCounter === 0 ? "grey" : "blue" }}
                  onClick={handleOnPressPrev}
                >
                  Prev
                </button>
                <section className="flex flex-col gap-2 items-center">
                  <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Monthly Rent {data[chartCounter].short_title}
                  </p>
                  {/* <h1 className="text-lg">
                    <p>₹{data[chartCounter].total_rent}</p>
                  </h1> */}
                </section>

                <button
                  style={{
                    color: chartCounter === data.length - 1 ? "grey" : "blue",
                  }}
                  onClick={handleOnPressNext}
                >
                  Next
                </button>
              </div>

              {/* <CircularMeter progress={10} leftProgress={90} /> */}
            </div>

            {/* <div className="calculator flex items-center">
              <ArrowOutwardIcon sx={{ color: "green" }} />
              <p style={{ color: "green" }}>
                {calculatePercentageChange(
                  data[chartCounter].total_rent,
                  previousMonthData && previousMonthData.total_rent
                )}
              </p>
              <p>
                vs {previousMonthData && previousMonthData.total_rent} prev
                month
              </p>
            </div> */}
            <div
              style={{
                // height: 45,
                // width: 423,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 40,
                marginTop: 20,
              }}
            >
              <RentCards
                label="Recieved"
                value={data[chartCounter].received_rent}
                color="text-green-500"
              />
              {/* <div>
                <p>Received</p>₹{data[chartCounter].received_rent}
              </div> */}
              {/* <div>
                <p>Due</p>₹
                {data[chartCounter].total_rent -
                  data[chartCounter].received_rent}
              </div> */}
              <RentCards
                label="Due"
                value={
                  data[chartCounter].total_rent -
                  data[chartCounter].received_rent
                }
                color="text-green-500"
              />
              {/* <div>
                <p>Units Paid</p>
                {data[chartCounter].tenants_paid}
              </div> */}
              <RentCards
                label="Units Paid"
                value={data[chartCounter].tenants_paid}
                color="text-green-500"
              />
              {/* <div>
                <p>Units Due</p>
                {data[chartCounter].total_tenants -
                  data[chartCounter].tenants_paid}
              </div> */}
              <RentCards
                label="Units Due"
                value={
                  data[chartCounter].total_tenants -
                  data[chartCounter].tenants_paid
                }
                color="text-green-500"
              />
              <RentCards
                label="Total"
                value={data[chartCounter].total_rent}
                color="text-green-500"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
