import React, { useEffect } from "react";
import Layout from "../Layout";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useState } from "react";
import { Select, TextField, Button, MenuItem, InputLabel } from "@mui/material";
import { useAuth } from "../../Utility/AuthProvider";
import APICall from "../../Utility/APICall";
// import { paths } from "../../Utility/Constants";
import { paths } from "../../Utility/Constants";
import { useNavigate } from "react-router-dom";

const amenitiesList = [
  "WiFi",
  "CCTV",
  "Television",
  "Parking",
  "Refrigerator",
  "Laundry",
  "Security",
  "Inverter",
];

function AddNewUnit() {
  const navigate = useNavigate();
  const furnishingItems = [
    { label: "Furnished", value: "Furnished" },
    { label: "Semi-Furnished", value: "Semi-furnished" },
    { label: "Unfurnished", value: "Unfurnished" },
  ];

  const initialValues = {
    unitName: "",
    floor: "",
    building: "",
    // locality: "",
    city: "",
    unitArea: "",
    furnishedType: "",
    bhk: "",
    noOfBalconies: "",
    noOfBathrooms: "",
    amenities: [],
  };

  const fileInputRef = React.useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Handle the file upload logic here
  };

  const handleIncrementBathrooms = () => {
    setBathrooms((prevBathrooms) => prevBathrooms + 1);
  };

  const handleDecrementBathrooms = () => {
    if (bathrooms > 1) {
      setBathrooms((prevBathrooms) => prevBathrooms - 1);
    }
  };

  const handleIncrementBalconies = () => {
    setBalconies((prevBalconies) => prevBalconies + 1);
  };

  const handleDecrementBalconies = () => {
    if (balconies > 1) {
      setBalconies((prevBalconies) => prevBalconies - 1);
    }
  };

  const [bathrooms, setBathrooms] = useState(1);
  const [balconies, setBalconies] = useState(1);
  const [floor, setFloor] = useState("");
  const [city, setCity] = useState("");
  const [furnishedType, setFurnishedType] = useState("");
  const [bhk, setBhk] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [dropdownValues, setDropdownValues] = useState(initialValues);

  const [unitName, setUnitName] = useState([]);
  const [unitArea, setUnitArea] = useState("");

  const [buildings, setBuildings] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState({});
  // const [entity_id, setEntity_id] = useState("")
  const [formdata, setFormData] = useState(initialValues);
  const { jwt } = useAuth();

  useEffect(() => {
    console.log("amenities -> ", amenities);
  }, [amenities]);

  // Handle change for dropdowns
  const handleDropdownChange = (event) => {
    console.log("event: ", event);

    const { name, value } = event.target;

    if (name == "city") {
      setCity(value);
    } else if (name == "bhk") {
      setBhk(value);
    } else if (name == "furnishedType") {
      setFurnishedType(value);
    } else if (name == "buildings") {
      console.log("value -> ", value);
      setSelectedBuilding(value);
    } else if (name == "floor") {
      setFloor(value);
    }

    setFormData({
      ...formdata,
      [name]: value,
    });
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    if (amenities.includes(value)) {
      setAmenities(amenities.filter((item) => item !== value));
    } else {
      setAmenities([...amenities, value]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const formData = new FormData(); // Create a new FormData object
    Object.entries(formdata).forEach(([key, value]) => {
      formData.append(key, value); // Append formdata fields to FormData object
    });
    formData.append("amenities", JSON.stringify(amenities)); // Append amenities as JSON string

    // Append selected dropdown values to form data
    Object.entries(dropdownValues).forEach(([key, value]) => {
      formData.append(key, value);
    });
    console.log("Form submitted with values:", Object.fromEntries(formData)); // Convert FormData to object and log
    // Handle form submission (e.g., send data to server)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "unitName") {
      setUnitName(value);
    } else if (name === "unitArea") {
      setUnitArea(value);
    } else {
      setFormData({
        ...formdata,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    console.log(formdata);
  }, [formdata]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const buildingResponse = await APICall(
          "GET",
          paths.building.fetch,
          undefined,
          undefined,
          jwt
        );

        console.log(buildingResponse);
        if (buildingResponse.status == "success") {
          console.log("Buildings on AddUnit:", buildingResponse.response);
          setBuildings(buildingResponse.response);
        } else {
          console.error("Failed to fetch buildings:", buildingResponse.error);
        }
      } catch (error) {
        console.error("Error fetching buildings:", error);
      }
    };

    fetchData();
  }, [jwt]);

  console.log("BUILDINGS ---", buildings);

  const updateBuildingCount = async (buildingId) => {
    try {
      // Fetch the current number of units for this building
      const buildingResponse = await APICall(
        "GET",
        `${paths.building.fetch}/${buildingId}`,
        undefined,
        undefined,
        jwt
      );

      if (!buildingResponse || !buildingResponse.response) {
        console.error("Failed to fetch building data", buildingResponse);
        return;
      }

      let currentUnits = buildingResponse.response.no_of_units || 0;

      const body = {
        building_id: buildingId,
        no_of_units: currentUnits + 1, // Send the new total count
      };

      const response = await APICall(
        "PUT",
        paths.building.updateCount,
        body,
        undefined,
        jwt
      );
      console.log("Building count updated:", response);
      console.log("Building count updated:", JSON.stringify(response, null, 2));
    } catch (error) {
      console.error("Failed to update building count:", error);
    }
  };

  const handleAddUnitToDatastore = async () => {
    try {
      const body = {
        building_id: selectedBuilding?.entity_id ?? "",
        unit_name: unitName,
        unit_area: unitArea,
        furnishing_status: furnishedType,
        bhk: bhk,
        no_of_bathrooms: formdata.noOfBathrooms,
        no_of_balcony: formdata.noOfBalconies,
        rent: "",
        security_amount: "",
        amenities: amenities,
        status: "active",
        occupancy_status: "vacant",
        building_name: selectedBuilding?.building_name ?? "",
        address: selectedBuilding?.address ?? "",
      };

      const res = await APICall("POST", paths.unit.add, body, undefined, jwt);
      console.log(res);

      if (res.status === "success") {
        alert("Unit added successfully!");

        // Update building count here
        if (selectedBuilding?.entity_id) {
          await updateBuildingCount(selectedBuilding.entity_id);
        }

        setUnitName(""); // Reset text field states
        setUnitArea("");

        setFormData({
          ...initialValues, // Reset dropdown values
        });

        setBathrooms(1);
        setBalconies(1);
        setSelectedBuilding({});
        setFurnishedType("");
        setBhk("");
        setAmenities([]);

        const newUnitEntityId = res.response.entity_id; // Get the entity_id from the response
        navigate(`/MyProperties/${newUnitEntityId}`, {
          state: { entity_id: newUnitEntityId },
        }); // Navigate with the correct entity_id and state
        return newUnitEntityId; // Return the new entity ID if needed

        // navigate(`/MyProperties/${unitName}`);
        // return res.response.entity_id;
      } else {
        alert("Failed to add unit. Please try again.");
      }
    } catch (error) {
      console.error("Error adding unit:", error);
      alert("An error occurred while adding the unit.");
    }
  };

  return (
    // <Layout>
    <div>
      <form onSubmit={handleSubmit}>
        {/* image container, container-1 */}
        <div style={{ flex: 0.15 }}>
          <label
            htmlFor="dropzone-file"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "200px",
              border: "2px dashed grey",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            <span
              style={{ marginBottom: "8px", fontSize: "14px", color: "grey" }}
            >
              Add Image
            </span>
            <Button
              onClick={handleUploadClick}
              variant="contained"
              color="primary"
              style={{ marginBottom: "8px" }}
            >
              Upload
            </Button>
            <input
              ref={fileInputRef}
              id="dropzone-file"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </label>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "start",
            gap: 10,
            marginTop: 20,
          }}
        >
          {/* container 2 and container 3 flexed */}

          <div style={{ flex: 0.5 }}>
            <p style={{ fontSize: 20, fontWeight: 700 }}>Unit Details</p>

            {/* details container container-2 */}
            <div style={{}}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  flex: 0.85,
                }}
              >
                <TextField
                  name="unitName"
                  id="unit-name"
                  label="Unit Name"
                  variant="outlined"
                  onChange={handleChange}
                  value={unitName}
                />

                {/* <div style={{}}>
                    <InputLabel sx={{}} htmlFor="floor">
                      Floor
                    </InputLabel>
                    <Select
                      name="floor"
                      id="floors"
                      value={floor}
                      onChange={handleDropdownChange}
                      style={{ width: "100%" }}
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                    </Select>
                  </div> */}

                <div style={{}}>
                  <InputLabel htmlFor="building-name">
                    Select Building
                  </InputLabel>
                  <Select
                    name="buildings"
                    value={selectedBuilding}
                    onChange={handleDropdownChange}
                    variant="outlined"
                    style={{ width: "100%" }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200, // Adjust the maximum height as needed
                          overflowY: "auto",
                        },
                      },
                    }}
                  >
                    {buildings.map((building, index) => (
                      <MenuItem key={index} value={building}>
                        {building.building_name},{building.address}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>

              {/* <div style={{ marginTop: "24px" }}>
                  <TextField
                    name="locality"
                    onChange={handleChange}
                    sx={{ width: "100%" }}
                    id="locality-area"
                    label="Locality/Area"
                    variant="outlined"
                  />
                </div> */}

              {/* <div style={{ marginTop: "24px" }}>
                  <InputLabel sx={{ paddingBottom: 1 }} htmlFor="countries">
                    Select City
                  </InputLabel>
                  <Select
                    name="city"
                    id="select-city"
                    value={city}
                    onChange={handleDropdownChange}
                    style={{ width: "100%" }}
                  >
                    <MenuItem value="Mumbai">Mumbai</MenuItem>
                    <MenuItem value="Delhi">Delhi</MenuItem>
                    <MenuItem value="Kolkata">Kolkata</MenuItem>
                    <MenuItem value="Banglore">Banglore</MenuItem>
                  </Select>
                </div> */}
              <TextField
                name="unitArea"
                onChange={handleChange}
                value={unitArea}
                sx={{ width: "100%", marginTop: 2 }}
                id="area"
                label="Area(sq.ft)"
                variant="outlined"
                style={{ marginBottom: "24px" }}
              />
              <div style={{ marginBottom: "24px" }}>
                <InputLabel sx={{ paddingBottom: 1 }} htmlFor="countries">
                  Furnished Type
                </InputLabel>
                <Select
                  name="furnishedType"
                  id="furnished-type"
                  value={furnishedType}
                  onChange={handleDropdownChange}
                  style={{ width: "100%" }}
                >
                  {furnishingItems.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div style={{ marginBottom: "24px" }}>
                <InputLabel sx={{ paddingBottom: 1 }} htmlFor="countries">
                  BHK
                </InputLabel>
                <Select
                  name="bhk"
                  id="bhk"
                  value={bhk}
                  onChange={handleDropdownChange}
                  style={{ width: "100%" }}
                >
                  <MenuItem value="1">1 BHK</MenuItem>
                  <MenuItem value="2">2 BHK</MenuItem>
                  <MenuItem value="3">3 BHK</MenuItem>
                </Select>
              </div>

              <div
                className="flex justify-between"
                style={{
                  borderBottom: ".5px solid gray",
                  paddingTop: "20px",
                  marginBottom: 20,
                }}
              >
                <p>No. of Balconies</p>
                <div className="flex items-center">
                  <Button onClick={handleDecrementBalconies}>
                    <RemoveCircleOutlineIcon />
                  </Button>
                  <TextField
                    onChange={handleChange}
                    name="noOfBalconies"
                    value={balconies}
                  />
                  <Button onClick={handleIncrementBalconies}>
                    <ControlPointIcon />
                  </Button>
                </div>
              </div>

              <div
                className="flex justify-between"
                style={{
                  borderBottom: ".5px solid gray",
                  paddingTop: "20px",
                }}
              >
                <p>No. of Bathrooms</p>
                <div className="flex items-center">
                  <Button onClick={handleDecrementBathrooms}>
                    <RemoveCircleOutlineIcon />
                  </Button>
                  <TextField
                    onChange={handleChange}
                    name="noOfBathrooms"
                    value={bathrooms}
                  />
                  <Button onClick={handleIncrementBathrooms}>
                    <ControlPointIcon />
                  </Button>
                </div>
              </div>

              <div style={{ marginTop: "40px", marginBottom: "20px" }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  type="submit"
                  style={{ color: "white" }}
                  onClick={handleAddUnitToDatastore}
                >
                  Add Unit
                </Button>
              </div>
            </div>
          </div>

          {/* amenities container, container-3 */}
          <div style={{ flex: 0.5, marginLeft: 20 }}>
            <p style={{ fontSize: 20, fontWeight: 700, paddingBottom: 20 }}>
              Amenities
            </p>

            <div
              className="options-container"
              style={{ display: "flex", flexDirection: "column", gap: 20 }}
            >
              {amenitiesList.map((amenity) => (
                <div
                  key={amenity}
                  className="option"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <label htmlFor={amenity}>{amenity}</label>
                  <input
                    name="amenities"
                    type="checkbox"
                    id={amenity}
                    style={{ transform: "scale(1.5)" }}
                    value={amenity}
                    checked={amenities.includes(amenity)}
                    onChange={handleCheckboxChange}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </form>
    </div>
    // </Layout>
  );
}

export default AddNewUnit;
