import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TenantContext } from "../Tenants/TenantContext";
import { AgreementContext } from "./AgreementContext";
import { useUnitDetails } from "../Properties/UnitDetailsContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Utility/AuthProvider";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import { formatDateForAPI } from "../../Utility/APICall";
import CongratsModal from "./CongratsModal";

const AgreementOverview = () => {
  const location = useLocation();

  const { addTenant, resetTenantData } = useContext(TenantContext); // Get all tenants
  const tenantData = location.state?.tenantData || []; // Get tenantData from state

  const { agreementData, addAgreementDetails, resetAgreementData } =
    useContext(AgreementContext); // Get all agreements
  const { unitDetails, error, fetchUnitDetails } = useUnitDetails(); // Use the hook
  //   const { units } = useContext(UnitContext);
  const { jwt } = useAuth();
  const unit = location.state || {};
  const navigate = useNavigate();

  const [addAgreement, setAddAgreement] = useState(null); // State to store API response
  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  console.log("JWT -", jwt);
  console.log(
    "Overview page Tenant Data from tenantContext 🥸🇮🇳 -",
    tenantData
  );

  //   //getting this data from Agreement Context via AddAgreement
  //   console.log("Agreement Data -", agreementData.agreementDetails.monthlyRent);
  //   console.log("Unit Details BUILDING_ID 😇 -", unitDetails.unit.building_id);
  //   console.log("Unit Details UNIT_ID(entity_id) -", unitDetails.unit.entity_id);
  //   console.log(
  //     "Date in this format Mon,20 Jan 2025 23:20:20 🔎-",
  //     agreementData.agreementDetails.stayingFrom
  //   );
  //   console.log(
  //     "Tenant ID 💋 -",
  //     tenantData.length > 0 ? [tenantData[0].entity_id] : []
  //   );
  //   console.log(
  //     "Add Agreement MONTHLY_RENT(rent) -",
  //     agreementData.agreementDetails.rent
  //   );
  //   console.log(
  //     "Add Agreement STAYING_FROM -",
  //     formatDateForAPI(agreementData.agreementDetails.stayingFrom)
  //   );
  //   console.log("JWT --", jwt);

  const formatDateForAPI = (date) => {
    if (!date) return null;

    const d = new Date(date);

    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, "0")}-${String(d.getDate()).padStart(2, "0")} ${String(d.getHours()).padStart(2, "0")}:${String(d.getMinutes()).padStart(2, "0")}:${String(d.getSeconds()).padStart(2, "0")}`;
  };

  useEffect(() => {
    if (unit && unit.entity_id) {
      fetchUnitDetails(unit.entity_id, jwt); // Call the function, pass unitId and jwt
    }
  }, [unit, fetchUnitDetails, jwt]); // Add fetchUnitDetails and jwt to dependency array

  const handleCreateAgreement = async () => {
    setLoading(true);

    try {
      // 1. Prepare the API request body:
      console.log("Tenant Data props 😀 -", tenantData);

      const requestBody = {
        unit_id: unitDetails.unit.entity_id, // From Unit Context
        // tenant_id: tenantData.length > 0 ? [tenantData[0].entity_id] : [],

        tenant_id: tenantData.map((tenant) => tenant.entity_id), // Map to
        // array of IDs
        // ... other agreement data

        building_id: unitDetails.unit.building_id,
        rent: agreementData.agreementDetails.monthlyRent,
        staying_from: formatDateForAPI(
          agreementData.agreementDetails.stayingFrom
        ),
        unit_name: unitDetails.unit.unit_name,
        building_name: unitDetails.unit.building_name,
        is_advance_rent: agreementData.agreementDetails.advanceRent,
        due_date: agreementData.agreementDetails.formattedDueDate,
        security_deposit: agreementData.agreementDetails.securityDeposit,
        maintenance: agreementData.agreementDetails.maintenanceCharge,
        tenure: agreementData.agreementDetails.tenure,
        vacating_on: formatDateForAPI(
          agreementData.agreementDetails.vacatingDate
        ),
      };
      // for debugging
      console.log("unit_id:", unitDetails?.unit?.entity_id);
      console.log("building_id:", unitDetails?.unit?.building_id);
      console.log(
        "tenant_id:",
        tenantData.length > 0 ? [tenantData[0]?.entity_id] : []
      );
      console.log("Request Body:", JSON.stringify(requestBody, null, 2));
      // 2. Make the API call:
      const response = await APICall(
        "POST", // Or PUT, depending on your API
        paths.agreement.add, // Your API endpoint
        requestBody,
        undefined,
        jwt
      );

      setAddAgreement(response); // Store the successful response
      console.log("API Response:", response);
      if (response.status === "success") {
        alert(`${tenantData.length} tenants agreement added successfully 🇮🇳`);
        setIsModalOpen(true);
        resetTenantData(); // Reset the tenant list *after* successful API call
        // Navigate with actual unit_id
        // navigate(`/MyProperties/${unitDetails?.unit?.entity_id}`);
      }
      // Optionally, navigate or show a success message:
      // navigate('/success'); // Or wherever you want to redirect
    } catch (error) {
      console.error("API Error:", error);
      setApiError(error.message || "An error occurred."); // Store the error message
    } finally {
      setLoading(false);
    }
  };

  console.log("Add Agreement Response -", addAgreement);

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-8">Agreement Overview</h1>

      {/* Section 1: Display All Tenants */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Tenants</h2>
        <div className="bg-white p-6 rounded-lg shadow-md">
          {tenantData.length > 0 ? (
            tenantData.map((tenant) => (
              <div
                key={tenant.entity_id}
                className="flex justify-between items-center border-b py-4"
              >
                <div>
                  <p className="font-medium">
                    {tenant.first_name}
                    {tenant.last_name}
                  </p>
                  <p className="text-sm text-gray-500">{tenant.mobile}</p>
                </div>
                <button
                  onClick={() => navigate(`/edit-tenant/${tenant.entity_id}`)}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                >
                  Edit
                </button>
              </div>
            ))
          ) : (
            <p>No tenants found.</p>
          )}
        </div>
      </section>

      {/* Section 2: Display All Agreements */}
      {/* Section 2: Display All Agreements */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Agreements</h2>
        <div className="bg-white p-6 rounded-lg shadow-md">
          {agreementData && agreementData.agreementDetails ? ( // Check if agreementData and agreementDetails exist
            <div className="border-b py-4">
              {" "}
              {/* No need for map, only one agreement detail */}
              <p className="font-medium">
                {/* Access agreement details directly */}
                Monthly Rent: {agreementData.agreementDetails.monthlyRent}
              </p>
              <p>Due Date: {agreementData.agreementDetails.dueDate}</p>
              <p>Tenure: {agreementData.agreementDetails.tenure} months</p>
              {/* ... other agreement details ... */}
            </div>
          ) : (
            <p>No agreement details found.</p>
          )}
        </div>
      </section>

      {/* Section 3: Display Unit Details */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Unit Details</h2>
        <div className="bg-white p-6 rounded-lg shadow-md">
          {/* Correct way to access unit details */}
          {unitDetails &&
            unitDetails.unit && ( // Check if unitDetails and unit exist
              <div key={unitDetails.unit.entity_id} className="border-b py-4">
                <p className="font-medium">
                  Unit Name: {unitDetails.unit.unit_name}
                </p>
                <p>Building: {unitDetails.unit.building_name}</p>
                <p>Address: {unitDetails.unit.address}</p>
                <p>Rent: {unitDetails.unit.rent}</p>
              </div>
            )}
        </div>
      </section>

      {/* Section 4: Create Agreement Button */}
      <div className="flex justify-end">
        <button
          //   onClick={() => navigate("/create-agreement")}
          onClick={handleCreateAgreement}
          className="bg-green-500 text-white px-6 py-2 rounded-md hover:bg-green-600"
        >
          Create Agreement
        </button>
      </div>
      {agreementData && (
        <CongratsModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          agreementData={agreementData}
          tenantData={tenantData}
          unitData={unitDetails}
          addAgreement={addAgreement}
        />
      )}
    </div>
  );
};

export default AgreementOverview;
